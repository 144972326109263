<template>
  <div class="d-flex big">
    <!-- 手机端 内容区域 -->
    <div class="content d-flex flex-wrap"  >
      <!--      支付方式 -->
      <!-- <div class="content-pay" style="border-radius:10px 0px 0px 10px">
        <div class="d-md-block d-none">
          <div class="d-flex justify-content-between w-100" style="font-size: 12px">
            <div class="d-flex align-items-center p-2 cursor-pointer col-12 justify-content-center"
                 :class="payModeActive=='Alipay'? 'active':''" @click="payModeAlipay">

              <div>
                <i class="iconfont text-white icon-zhifubao  cursor-pointer" style="font-size:35px"></i>
              </div>
              <div>支付宝充值</div>
            </div>
            
          </div>
        </div>
        <div class="mt-5 d-sm-block d-none text-center" style="font-size: 18px">
            <div class="mb-3">实付金额</div>
            <div style="font-size:16px">
                <span style="font-size:12px">RMB</span>{{resData.price}}
            </div>


            </div>


            <div class="d-sm-block d-none">
                <div class="mt-5 d-flex justify-content-center">
                    <img class="" width="200px" height="200px" v-lazy="codeIsShow || require('@/assets/img/code_pay.png')" alt="">
                </div>
            </div>


            <div class="text-center d-sm-block d-none mt-4" style="font-size: 12px">
                请使用 <span class="color-yellow">”{{payModeActive=='Alipay'?'支付宝':'微信'}}扫一扫“</span>确认支付
                <div class="mt-2">二维码只能使用一次，再次充值请重新选择金额</div>
            </div>

            <div class="mt-5 d-sm-block d-none text-center">

                <div>
                  <i class="iconfont text-white icon-dunpai  cursor-pointer" style="font-size:20px"></i>
                </div>
                支付环境安全，请放心使用
            </div>
        </div> -->
      <!--      选择金额 -->
      <div
        class="content-money p-sm-5"
        style="border-radius: 0px 10px 10px 0px"
      >
        <!-- title -->
        <div class="title d-flex">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <p class="titletext family">在线充值</p>
          <p></p>
        </div>
        <!-- 等级 -->
        <div class="d-flex level">
          <div class="d-flex level-left" style="font-size:16px;">
            <span style="color: #fff;">当前等级</span>
            <div class="level-left-right family" style="color: #099bf0">
              lv{{ user_info.vip_level }}
            </div>
          </div>
          <div style="color:#333" class="level-right family">{{ user_info.vip_level }}</div>
        </div>
        <!-- 当前等级 V{{ user_info.vip_level }} -->
        <!-- 协议弹窗口 -->
        <div v-show="isShow" class="xieyi">
            <div class="xytitle">
              <span>请阅读一下协议并勾选同意<br>然后点击同意前去充值</span>
            </div>
            <div class="xycheck">
              <p>
                <input name="checkbox" value="Item 1" v-model="isTrue" :checked="isTrue" type="checkbox" class="tui-checkbox ">
                本人已满18周岁且具有完全民事行为能力
              </p>
              <p>
                <input name="checkbox" value="Item 1" v-model="isTrue" :checked="isTrue" type="checkbox" class="tui-checkbox ">
                本人仅在经济承受范围内消费，且将为消费行为承担后果

              </p>
              <p>
                <input name="checkbox" value="Item 1" v-model="isTrue" :checked="isTrue" type="checkbox" class="tui-checkbox ">
               本人已知通过充值购买的虚拟物品不可退货或换货，且不根据七天无理由退换货主张权利

              </p>
              <p>
                <input name="checkbox" value="Item 1" v-model="isTrue" :checked="isTrue" type="checkbox" class="tui-checkbox ">
                本人已知饰品发起提取流程后，通常在24小时完成

              </p>
            </div>
            <button v-if="isTrue" class="xybtn" @click="isShow=false"><span class="family">我已<br/>同意</span></button>
            <button v-else class="xybtn1"><span class="family">我已<br/>同意</span></button>
        </div>
        <!-- 提示 -->
        <ul class="tip-List">
          <li>如果充值遇到问题，请更换充值方式，或联系客服</li>
          <li>充值即可开启免费宝箱</li>
          <li>客服QQ(交流群883725229)</li>
        </ul>
        <!--    选择金额    -->
        <div class="amount d-flex">
          <div
            v-for="(item, index) in beansList"
            :key="index"
            @click="beansClick(item, index + 1)"
            :class="index == beansIndex - 1 ? 'active' : ''"
          >
            <div>{{ item.price }}</div>
          </div>
        </div>

        <!-- <div class="d-flex">
          <div
            class=" amount"
            v-for="(item, index) in beansList"
            :key="index"
            @click="beansClick(item, index + 1)"
            :class="index == beansIndex - 1 ? 'active' : ''"
          > -->
        <!-- <div class="d-md-flex d-block justify-content-center mt-md-0 mt-3">
              <div class="mr-2 h4">
                {{ (item.bean * 1).toFixed(0) }}
              </div>
            </div> -->
        <!-- <div style="font-size: 12px">RMB {{ item.price }}</div> -->
        <!-- 折扣标签 -->
        <!-- <div
              class="position-absolute content-money-list-item-vip px-3 py-1 text-white"
              style="
                transform: rotate(-45deg);
                background-color: var(--main-blue);
              "
              v-if="user_info.is_recharge == 0"
            >
              <span>{{ item.firdt.ratio }}.00%</span>
            </div> -->
        <!-- <div v-if="user_info.is_recharge == 0">
              {{ item.firdt.description }}
            </div> -->
        <!-- </div>
        </div> -->
        <!-- 充值金额title -->
        <p class="top-upTitle family">充值方式</p>
        <!-- 充值 -->
        <div class="top-up d-flex">
          <!-- 手机端充值按钮 -->
          <div class="top-up-left family">
            <!-- 支付宝 -->
            <div>
              <div
                class="d-flex align-items-center p-2 cursor-pointer col-12 justify-content-center"
                :class="payModeActive == 'Alipay' ? 'active' : ''"
                @click="payModeAlipay"
              >
                <i class="iconfont text-white icon-zhifubao"></i>支付宝
              </div>
            </div>
            <!-- 微信 -->
            <div>
              <div
                class="d-flex align-items-center p-2 cursor-pointer col-12 justify-content-center"
                :class="payModeActive == 'Alipay' ? 'active' : ''"
              >
                <img src="../../assets/imgV2/user/wx.png" alt="">微信
              </div>
            </div>
          </div>
          <!-- 卡密充值 -->
          <div class="top-up-right">
            <input
              class="top-up-inp top-up-com"
              type="text"
              v-model="CardRecharge"
              placeholder="请输入卡密"
            />
            <button class="top-up-but top-up-com" @click="getCardRecharge">
              充值
            </button>
          </div>
        </div>

        <!-- 提示 -->
        <!-- <div class="mt-5 bg-size bg-repeat justify-content-center d-flex align-items-center">

              <div style="font-size: 12px;line-height: 28px" class="title_qian">
                  为了保证您能顺利的领取到奖品，请您再充值前确认本账号是否被
                  Steam限制交易，请自行迁往Steam社区查看该账号的交易安全内容
              </div>
          </div> -->
      </div>
    </div>
    <!-- 电脑端吗 -->
  </div>
</template>


<script>
import $api from "@/api/user/index";
import $apiVip from "@/api/vip/index";
import sUserAgent from "@/assets/js/common.js";
import { mapState } from "vuex";

export default {
  name: "recharge",
  data() {
    return {
      isShow:true,
      isTrue:false,
      isiOS: false,
      isiOSUrl: "",
      tableData: [],
      beansList: [],
      beansIndex: 100,
      resData: {
        price: "",
      },
      CardRecharge: "",
      payModeActive: "Alipay",
      payUrl: "",
      money: "",
      time: "time",
      total: 0,
      pageSize: 0,
      firdtList: [],
      beansClickData: {
        id: 0,
      },
      Vip: {},
      Viptips: {},
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["user_info", "web_info"]),
    codeIsShow: {
      get() {
        return this.payUrl;
      },
    },
    sUserAgent() {
      return sUserAgent.sUserAgent();
    },
  },
  beforeDestroy() {
    clearInterval(this.time);
  },
  methods: {
    
    goBack(){
      this.$router.go(-1)
    },
    async init() {
      this.getBeans();
      this.getRecharge_record();

      setTimeout(() => {
        this.money = JSON.parse(
          JSON.stringify(this.$store.state.user_info.bean)
        );
        this.getVipList();
      }, 500);
    },
    async getBeans() {
      try {
        let { data } = await $api.getBeans();
        let { data: firdt } = await $api.getbeans_first();
        console.log("data,firdt");
        data.forEach((item) => {
          firdt.forEach((t) => {
            if (t.bean == item.bean) {
              item.firdt = t;
            }
          });
        });
        console.log(data);
        this.firdtList = firdt;
        this.beansList = data;
        this.resData.price = data[0].price;
      } catch (e) {
        console.log(e);
        return;
      }
    },
    getVipList() {
      $apiVip.getVipList().then((res) => {
        this.Vip = res.data.list.find((item) => {
          return item.level == this.user_info.vip_level;
        });

        this.Viptips = res.data.tips;
      });
    },
    beansClick(item, index) {
      this.beansIndex = index;
      this.resData.price = item.price;
      let data = {
        id: item.id,
        pay_way: 1,
      };
      this.beansClickData = data;
      if (this.sUserAgent === "web") {
        this.payModeAlipay();
      }
    },
    payModeAlipay() {
      if (this.beansClickData.id == 0) {
        this.$message("选择充值金额");
        return;
      }
      this.payModeActive = "Alipay";
      this.getRecharge({ id: this.beansClickData.id, pay_way: 1 });
    },

    async getCardRecharge() {
      try {
        let { code, message } = await $api.getCardRecharge({
          card: this.CardRecharge,
        });
        if (code == 500) return 
        if (code == 200) {
          this.$message.success(message);
          this.CardRecharge = "";
        }
        this.init();
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async getRecharge_record(page = 1) {
      try {
        let { data, code } = await $api.getRecharge_record(page);
        if (code == 500) return;
        this.tableData = data.data;
        this.total = data.total;
        this.pageSize = data.per_page;
      } catch (e) {
        console.log(e);
        return;
      }
    },
    currentChange(val) {
      this.getRecharge_record(val);
    },
    async getRecharge(data = { id: 1, pay_way: 6 }) {
      let iosUrl = null;
      if (this.sUserAgent != "web") {
        iosUrl = window.open("", "_blank ");
      }
      let loading = null;
      try {
        loading = this.$loading({
          lock: true,
          text: "正在加载",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let { data: resData, code, message } = await $api.getRecharge(data);

        if (code == 500) {
          this.$message("错误，" + message);
          loading.close();
          return;
        }

        if (this.sUserAgent == "web") {
          this.payUrl = resData.list.qr_url;
        } else {
          iosUrl.location = resData.list.h5;
        }
        setTimeout(async () => {
          loading.close();
          this.$message("请稍后......");
          this.getUsetInof();
        }, 500);
      } catch (e) {
        loading.close();
        this.getUsetInof();
        this.$message("抱歉，请稍后......");
        let routeUrl = this.$router.resolve({
          path: "/user/Payment",
          query: {
            id: data.id,
            pay_way: data.pay_way,
          },
        });
        window.open(routeUrl.href, "_self");
      }
    },
    async getUsetInof() {
      this.time = setInterval(async () => {
        try {
          let { data, code } = await $api.getUsetInof();
          if (code == 500) return;
          if (data.bean > this.money) {
            this.$store.dispatch("userInfo");
            this.$message.success("充值成功");
            this.money = JSON.parse(
              JSON.stringify(this.$store.state.user_info.bean)
            );
            clearTimeout(this.time);
          }
        } catch (e) {
          console.log(e);
        }
      }, 1000);
    },
  },
};
</script>


<style scoped lang="scss">
.xieyi{
	width: 88%;
	height: 630px;
	background-color: rgba(61, 61, 61, 0.829);
	border-radius: 10px;
  z-index: 99999;
position: absolute;
    top: 21%;
    left: 50%;
    transform: translateX(-50%)
}
.xytitle{
      text-align: center;
    font-size: 20px;
    margin: 55px 25px;
    line-height: 1.5;
}
.xycheck{
  width: 80%;
  margin: 50px auto;
  font-size: 14px;
  
}
.xycheck p{
  margin: 20px 0;
}
.xybtn{
  font-size: 24px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: url('../../assets/image/6.png');
  background-size: 100% 100%;
  position: relative;
   left: 50%;
    transform: translateX(-50%);
    border: none;
}
.xybtn1{
  font-size: 24px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: url('../../assets/image/56.png');
  background-size: 100% 100%;
  position: relative;
   left: 50%;
    transform: translateX(-50%);
    border: none;
}
.xybtn span{
  position: absolute;
  top :26%;
  left: 26%;
  color: #fff;
    -webkit-text-stroke: 1px #000000;
}
.xybtn1 span{
  position: absolute;
  top :26%;
  left: 26%;
  color: #fff;
    -webkit-text-stroke: 1px #000000;
}
.tui-checkbox:checked {
	background:#A0DC34
}

.tui-checkbox {
	width:15px;
	height:15px;
	background-color:#ffffff00;
	border:solid 1px #A0DC34;
	-webkit-border-radius:50%;
	border-radius:50%;
	font-size:0.8rem;
	margin:0;
	padding:0;
	position:relative;
	display:inline-block;
	vertical-align:top;
	cursor:default;
	-webkit-appearance:none;
	-webkit-user-select:none;
	user-select:none;
	-webkit-transition:background-color ease 0.1s;
	transition:background-color ease 0.1s;
}

.tui-checkbox:checked::after {
	content:'';
	top:2px;
	left:3px;
	position:absolute;
	background:transparent;
	border:#fff solid 2px;
	border-top:none;
	border-right:none;
	height:6px;
	width:10px;
	-moz-transform:rotate(-45deg);
	-ms-transform:rotate(-45deg);
	-webkit-transform:rotate(-45deg);
	transform:rotate(-45deg);
}
.big {
  background-color: #05377b;
  overflow: hidden;
  position: absolute;
  top: 8%;
  padding-bottom: 90px;
}
.title {
  padding-left: 20px;
  align-items: center;
  justify-content: space-between;
}
.titletext {
  margin-left: -10px;
  font-size: 30px;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 2px #98d462;
}
.level {
  margin: 35px 0 20px;
  justify-content: space-around;
  align-items: center;
}
.level-left {
  width: 220px;
  height: 45px;
  line-height: 45px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.271);
  justify-content: space-around;
}
.level-left-right {
  width: 80px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background-image: url("../../assets/imgV2/user/border-blue.png");
  background-size: 100% 100%;
}
.level-right {
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  background-image: url("../../assets/imgV2/user/level-right.png");
  background-size: 100% 100%;
}
.tip-List {
  color: #8acf80;
  font-size: 10px;
  padding-left: 30px;
  margin-bottom: 20px;
  list-style-type: disc !important;
}
.tip-List li{
  margin-bottom: 10px;
}
.amount {
  flex-wrap: wrap;
}
.amount > div {
   width: 46%;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 25px;
  color: #fff;
  font-weight: bolder;
  background-image: url("../../assets/imgV2/user/amount-s.png");
  background-size: 100% 100%;
  margin-left: 10px;
}
.amount > div.active {
   width: 46%;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 25px;
  color: #fff;
  font-weight: bolder;
  background-image: url("../../assets/imgV2/user/amount.png");
  background-size: 100% 100%;
  margin-left: 10px;
}
.top-upTitle {
  font-size: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 2px #98d462;
}
.top-up {
  padding: 0 20px;
  align-items: center;
}
.top-up-left {
  margin-right: 5px;
}
.top-up-left > div {
  width: 110px;
  background-image: url("../../assets/imgV2/user/top-upBg-s.png");
  background-size: 100% 100%;
  color: white;
  text-align: center;
  font-size:16px;
}
.top-up-left > div.active{
  background-image: url("../../assets/imgV2/user/top-upBg.png");
  color: #009fff;
  font-size:16px;
}
.top-up-com {
  width: 220px;
  height: 45px;
  text-align: center;
  color: white;
  border-radius: 15px;
  border: transparent;
}
.top-up-inp {
  background-color: rgba(0, 0, 0, 0.467);
  outline: none;
  margin-bottom: 5px;
  font-size: 16px;
}
.top-up-but {
  background-color: #009fff;
  font-size: 16px;
}
</style>
